import React from 'react'
import Container from './Container'
import { AiOutlineCode, AiOutlineTool, AiOutlineFileText, AiOutlineRobot } from 'react-icons/ai';


export default function AuditFeature() {

    const data = [
        {
            title: "AI-Driven Code Analysis",
            text: "Utilizes advanced machine learning algorithms and natural language processing to automate the examination of software source code.",
            icon: <AiOutlineCode />
        },
        {
            title: "Automated Testing and Simulation",
            text: "Simulation of diverse scenarios to stress-test contracts and uncover potential weaknesses.",
            icon: <AiOutlineTool />
        },
        {
            title: "Real-time Comprehensive Reports",
            text: "Immediate generation of detailed audit reports outlining vulnerabilities and actionable insights.",
            icon: <AiOutlineFileText />
        },
        {
            title: "Adaptive Machine Learning Models",
            text: "The continual learning process of our AI Model enables it to adapt and evolve based on emerging threats.",
            icon: <AiOutlineRobot />
        }
    ];

    return (
        <div className="wrap pb-32">
            <Container>
                <div className="flex gap-10 items-center">
                    <img src="/asset/shield.png" alt="stuff" className='hidden lg:block' />
                    <div className="wrap">
                        <div className="heading text-white text-3xl">
                            <span>Alpha AI Audit is Built <soan className="text-primary">for a Robust Auditing</soan> Experience</span>
                        </div>
                        <div className="grid lg:grid-cols-2 lg:mt-5 mt-10 gap-10">
                            {data.map((i, index) => (
                                <div className="wrap" key={index}>
                                    <div className="icon-wrap flex">
                                        <h1 className='text-3xl bg-primary p-3 rounded-full'>{i.icon}</h1>
                                    </div>
                                    <h1 className='text-xl text-gray-100 py-4'>{i.title}</h1>
                                    <span className='text-sm text-gray-400'>{i.text}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </Container>

            <Container>
                <div className="wrap text-center tt mt-32">
                    <div className="bg-con p-5 bg-primary bg-opacity-20 py-10 backdrop-blur-sm rounded-lg text-white">
                        <p className='text-3xl'>Ready to audit your smart contract, <br /> securely?</p>
                        <div className="btn-wrap mt-10 flex flex-wrap gap-10 justify-center">
                            <a target='_blank' href='https://t.me/AlphaAuditBot' className='font-bold text-gray-200 rounded-full p bg-primary p-4 px-10 text-md'>Try Alpha AI Audit for Free</a>
                            <a target='_blank' href='https://docs.alphai.bot/alpha-audit' className='font-bold text-secondary rounded-full p bg-white p-4 px-10 text-md'>Learn More</a>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    )
}
