import React from 'react'
import Container from './Container'
import PriceCard from './UI/PriceCard'
import radial from '../svg/radialOne.svg'

export default function Pricing() {
    const basic = [
        '❌ FOMO Alerts',
        '❌ Approvals Filter',
        '❌ Socials Filter',
        '❌ Clog Filter',
        '❌ Bundles Filter',
        '❌ Airdrop Filter'
    ]
    const pro = [
        '✅ FOMO Alerts',
        '✅ Approvals Filter',
        '✅ Socials Filter',
        '✅ Clog Filter',
        '✅ Bundles Filter',
        '✅ Airdrop Filter'
    ]
    const auto = ["Al basic feature", "All Pro Filter Feature", "Auto trading feature"]
    return (
        <div className="wrap py-32" id='pricing'>

            <Container>
                <div className="lg:hidden heading text-center text-gray-200">
                    <h1 className='text-5xl font-extrabold mb-2'>Pricing</h1>
                    <span className='text-lg text-gray-300'> Find the perfect plan to suit your trading needs.</span>
                </div>

                <div className="grid items-center lg:grid-cols-3 gap-10 pt-20 relative">
                    <img src={radial} alt="radial" className='absolute z-[-1] my-auto bottom-0 left-0 top-0 right-0' />

                    <PriceCard plan={"Basic Filters"} price={"Free"} text={"Unlock essential filters for free and start exploring Ethereum pairs effortlessly."} benefit={basic} />

                    <div className="hidden lg:block heading text-center text-gray-200">
                        <h1 className='text-5xl font-extrabold mb-2'>Pricing</h1>
                        <span className='text-lg text-gray-300'> Find the perfect plan to suit your trading needs.</span>
                    </div>

                    <PriceCard plan={"Pro Filters"} price={"0.1% of $ALAI"} text={"Access advanced filters for just 0.1% of your $ALAI balance, enhancing your trading capabilities."} benefit={pro} />
                </div>
            </Container>
        </div>
    )
}
