import React from 'react'
import Header from './Components/Header'
import Intro from './Components/Intro'
import Features from './Components/Features'
import About from './Components/About'
import Docs from './Components/Docs'
import Pricing from './Components/Pricing'
import Footer from './Components/Footer'
import Partners from './Components/Partners'
import OurBots from './Components/OurBots'
import AuditBot from './Components/AuditBot'
import AuditFeature from './Components/AuditFeature'
import Chains from './Components/Chains'

export default function Home() {
  return (
    <div className="wrap">
      <Header />
      <Intro />
      <OurBots />
      <AuditBot />
      <AuditFeature />
      <About />
      <Features />
      <Chains />
      <Docs />
      <Pricing />
      <Partners />
      <Footer />
    </div>
  )
}
