import React from 'react'
import Container from './Container'
import { FaEnvelope, FaGlobe, FaTelegram, FaTwitter } from "react-icons/fa"

export default function Footer() {
	const currentYear = new Date().getFullYear();
    const iconsFunc = (link, icon) => ({ link, icon })
    const iconArr = [
        iconsFunc("https://t.me/alphaiportal", <FaTelegram />),
        iconsFunc("https://x.com/alphaibot1", <FaTwitter />),
        iconsFunc("mailto:support@alphai.bot", <FaEnvelope />),
        iconsFunc("#", <FaGlobe />),
    ]
    return (
        <div className="wrap bg-white bg-opacity-5">
            <Container>
                <div className="heading flex flex-wrap gap-5 items-center justify-between border-b-[1px]">
                    <img src="/asset/logo.png" alt="Logo" className='w-[100px]' />

                    <div className="socials flex gap-3">
                        {iconArr.map((i, index) => (
                            <a href={i.link} key={index} className='text-white text-2xl bg-white bg-opacity-20 rounded-full p-2 '>{i.icon}</a>
                        ))}
                    </div>
                </div>
                <div className="copy text-center text-white text-xl py-5 mt-5">
                    <span>COPYRIGHT © {currentYear} | <span className='text-primary font-bold' >ALPHA AI.</span> All Right Reserved </span>
                </div>
            </Container>
        </div>
    )
}
