import React from 'react'

export default function Card({ icon, title, text }) {
    return (
        <div className="wrap">
            <div className="card bg-white bg-opacity-10 rounded-3xl p-6 py-10">
                <div className="icon-wrap flex">
                    <div className="img-wrap rounded-2xl p-3 bg-primary">
                        <img src={icon} alt={'Icon'} className='w-[30px]' />
                    </div>
                </div>
                <div className="text-wrap text-gray-200 mt-5 leading-7">
                    <h3 className='text-xl font-extrabold mb-3'>{title}</h3>
                    <span className='text-lg'>{text}</span>
                </div>
            </div>
        </div>
    )
}
