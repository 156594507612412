import React from 'react'
import Container from './Container'
import lock from '../svg/lock.svg'
import secure from '../svg/secure.svg'
import uptime from '../svg/uptime.svg'
import fast from '../svg/fast.svg'
import radial from '../svg/radialOne.svg'
import arrow_down from '../svg/arrow_down.svg'

export default function About() {
    const listFunc = (icon, text) => ({ icon, text })
    const listArr = [
        listFunc(lock, "100% Private data"),
        listFunc(secure, "256-bit secure encryption"),
        listFunc(uptime, "99.99% Uptime guarantee"),
        listFunc(fast, "Fast and secure transactions"),
        // listFunc(arrow_down, "Lowest fees in market"),
    ]
    return (
        <div className="wrap py-16 relative border-t-primary border-t-[1px]">
            <Container>
                <div className="grid lg:grid-cols-2 gap-10 items-center" id='about'>
                    <div className="img-wrap">
                        <img src="/asset/logo.png" alt="Alpha AI" />
                    </div>
                    <div className="text-wrap text-gray-200">
                        <div className="heading text-4xl lg:text-5xl mb-6 font-extrabold">
                            <h1>About <span className='text-primary'>New Pair Finder for SOL and ETH</span></h1>
                        </div>
                        <div className="text-wrap">
                            <span className='lg:text-xl'>
                                The new pair Finder Bot simplifies trading by swiftly identifying new pairs onchain. Tailored for traders of all levels, it offers real-time data and customizable filters, empowering informed decisions.
                            </span> <br /> <br />
                            <span className='lg:text-xl'>
                                Whether you're experienced or new to crypto, our bot equips you with confidence to navigate Ethereum effortlessly.
                            </span>

                            <div className="btn-wrap mt-10">
                                <a target='_blank' href='https://t.me/alphaifinderbot' className='font-bold text-gray-200 rounded-full p bg-primary p-4 px-10 text-md'>GET STARTED</a>
                            </div>
                        </div>
                    </div>
                </div>

                <img src={radial} alt="radial" className='absolute z-[-1] w-[800px] my-auto top-0 right-0' />
                {/* <img src={radial} alt="radial" className='absolute z-[-1] w-[500px] my-auto bottom-0 left-0 ' /> */}




                {/* <div className="grid lg:grid-cols-2 gap-10 items-center mt-32">
                    <div className="text-wrap text-gray-200">
                        <div className="heading text-4xl lg:text-5xl mb-6 font-extrabold">
                            <h1>Built on a robust and powerful platform</h1>
                        </div>
                        <div className="text-wrap">
                            <span className='lg:text-xl'>
                                Harnessing a robust and powerful platform, our ETH Pair Finder Bot ensures top-notch performance. With advanced technology driving its core, it offers seamless navigation through the Ethereum ecosystem, empowering traders to explore new pairs confidently.
                            </span>
                        </div>

                        <ul className='mt-10'>
                            {listArr.map((i, index) => (
                                <li className='flex gap-3 items-center text-xl text-white font-bold mb-4' key={index}><div className="icon-wrap rounded-lg bg-primary p-2"><img src={i.icon} alt={"lock"} className='w-[20px]' /></div> <span>{i.text}</span></li>
                            ))}
                        </ul>
                    </div>
                    <div className="img-wrap flex justify-end">
                        <img src="/asset/tg-bot.png" alt="Alpha AI" className='w-[500px]' />
                    </div>

                </div> */}
            </Container>
        </div>
    )
}
