import React from 'react'
import Container from './Container'

export default function Docs() {
    return (
        <div className="wrap py-10 relative mt-28">
            <div className="bg-primary flex py-10 ">
                <Container>
                    <div className="grid lg:grid-cols-2 text-white">
                        <div className="wrap">
                            <h1 className='text-4xl font-extrabold mb-5 uppercase'>
                                Read more about ALPHA AI's ETH & SOL pair finder
                            </h1>
                            <span className='lg:text-xl'>
                                Discover ALPHA AI's ETH & SOL pair finder for precise trading. Powered by AI, it identifies lucrative ETH & SOL pairs. Whether you're new or experienced, our user-friendly interface ensures easy navigation. Stay ahead with ALPHA AI.
                            </span>
                            <div className="btn-wrap mt-10">
                                <a target='_blank' href='https://docs.alphai.bot' className='font-extrabold text-primary rounded-full p bg-white p-4 px-10 text-md'>READ DOCUMENTATION</a>
                            </div>
                        </div>

                        <div className="img-wrap relative  pt-10">
                            <img src="/asset/phone1.png" alt="phone" className='lg:absolute bottom-0 w-[250px] lg:translate-x-[100px] rotate-[-10deg]' />
                            <img src="/asset/phone2.png" alt="phone" className='absolute bottom-0 right-[24px]   lg:top-auto w-[250px]  rotate-[10deg] ' />
                        </div>
                    </div>
                </Container>
            </div>

        </div>
    )
}
