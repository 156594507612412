import React from 'react'
import Container from './Container'

export default function AuditBot() {
    const card = [
        { title: "MarketCap Assessed", value: '$800M+' },
        { title: "Smart Contract Audits", value: '240+' },
        { title: "Vulnerabilities Detected", value: '900+' }
    ]
    return (
        <div className="wrap py-32">


            <Container>
                <div className="grid lg:grid-cols-2 gap-10 items-center" id='about'>
                    <div className="img-wrap relative">
                        <img src="/asset/audit2.png" alt="Alpha AI" className='w-[200px] ' />
                        <img src="/asset/audit1.png" alt="Alpha AI" className='w-[200px] absolute top-0 right-36' />
                    </div>
                    <div className="text-wrap text-gray-200">
                        <div className="heading text-4xl lg:text-5xl mb-6 font-extrabold">
                            <h1>What is Alpha AI Automated Audit Bot</h1>
                        </div>
                        <div className="text-wrap">
                            <span className='lg:text-xl'>
                                Our automated AI contract audit provides efficient and accurate analysis. Utilizing advanced algorithms, it ensures compliance and identifies risks swiftly. Powered by machine learning, it adapts to changes in legal requirements, offering real-time insights.
                            </span> <br /> <br />

                            <div className="btn-wrap mt-10">
                                <a target='_blank' href='https://t.me/AlphaAuditBot' className='font-bold text-gray-200 rounded-full p bg-primary p-4 px-10 text-md'>GET STARTED</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="wrap bg-primary p-10 rounded-3xl text-center mt-16">
                    <div className="heading text-white text-lg mb-4">
                        <span>AlPHA AI AUDIT IN THE NUMBERS</span>
                    </div>

                    <div className="grid items-center lg:grid-cols-3 gap-10">
                        {card.map((i, index) => (
                            <div className="wrap" key={index}>
                                <h1 className='text-xl mb-3'>{i.title}</h1>
                                <span className='text-3xl text-secondary'>{i.value}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </Container>
        </div>
    )
}
