import React from 'react'
import Container from './Container'
import radial from "../svg/radialOne.svg"
import copyToClipboard from '../../utils/copy'
import { FaClipboard } from 'react-icons/fa'

export default function Intro() {
    return (
        <div className="intro relative overflow-clip">
            <Container>
                <img src={radial} alt="radial" className='absolute z-[-1] w-[800px] translate-x-[-300px] translate-y-[-200px]' />
                <img src={radial} alt="radial" className='absolute z-[-1] w-[800px] translate-x-[200px] translate-y-[200px] right-0' />
                <div className="lg:w-1/2 items-center py-32 z-10 relative mx-auto text-center">
                    <div className="text-wrap text-gray-200 py-8">
                        <h1 className='font-extrabold text-4xl lg:text-4xl mb-10 z-10'>
                            Effortlessly catch new gems <span className='text-primary'></span>with Alpha Finder.<br /> Audit tokens on 8 chains <span className='text-primary'>with Alpha Audit</span>.
                        </h1>
                        <span className='lg:text-lg'>
                            Introducing <span className='text-primary'>Alpha AI</span> is a suite of AI powered tools to help you securely find and scan new/existing tokens.
                        </span>
					<hr/>
					<div className="ca-wrap">
                        <span className="text-gray-100 font-sans text-lg font-bold">
                            $ALAI <span className=' text-primary'>Contract Address</span>
                        </span>
                        <div className="flex">
                            <div className="wrap flex gap-5 justify-between items-center px-3 py-2 rounded-lg  grad-border bg-white w-[500px] mx-auto bg-opacity-10 text-white">
                                <span className="max-w-[400px] overflow-hidden text-ellipsis"  onClick={() => copyToClipboard("0x3f6BCdc39f8ac795e7168febad7e262e325450F7")}>0x3f6BCdc39f8ac795e7168febad7e262e325450F7</span>
                                <span onClick={() => copyToClipboard("0x3f6BCdc39f8ac795e7168febad7e262e325450F7")} className="cursor-pointer">
                                    <FaClipboard />
                                </span>
                            </div>
                        </div>
                    </div>
					
                        <div className="btn-wrap flex gap-5 mt-10">
                            <a target='_blank' href='https://t.me/alphaifinderbot' className='font-bold text-gray-200 rounded-full p bg-primary p-4 px-6 text-md w-full'>Launch Bot</a>
                            <a href='#pricing' className='font-bold text-gray-200 rounded-full p-4 px-6 text-md bg-gray-200 bg-opacity-40 w-full'>Bot Pricing </a>
                        </div>
                    </div>

                    {/* <div className="img-wrap flex justify-end">
                        <img src="/asset/phone1.png" alt="phone" className='absolute z-[-1] right-0 lg:right-auto top-[120px] lg:top-[100px] w-[250px] mx-auto md:translate-x-[100px] rotate-[-10deg]' />
                        <img src="/asset/logo.png" alt="phone" className=' backdrop-blur-sm' />
                    </div> */}
                </div>
            </Container>
        </div>
    )
}
