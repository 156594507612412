import React from 'react'
import Container from './Container'
import cgc from "../svg/cgc.svg"

export default function Partners() {
    const imgArr = [cgc, '/asset/ttf.png']
    return (
        <div className="wrap py-16 bg-black bg-opacity-10" id='partners'>
            <Container>
                <div className="heading mb-10">
                    <h1 className='text-4xl font-extrabold text-white text-center'> <span className='text-primary'>Our</span> Partners</h1>
                </div>

                <div className="flex justify-center gap-10">
                    {imgArr.map((i, index) => (
                        <div className="wrap" key={index}>
                            <img src={i} alt={i} className='w-[300px]' />
                        </div>
                    ))}
                </div>
            </Container>
        </div>
    )
}
