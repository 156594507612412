import React from 'react'
import Container from './Container'

export default function Chains() {
    const chainImg = [
        "https://assets.coingecko.com/coins/images/279/standard/ethereum.png",
        "https://assets.coingecko.com/coins/images/4128/standard/solana.png?1696504756",
        "https://assets.coingecko.com/coins/images/16547/standard/photo_2023-03-29_21.47.00.jpeg?1696516109",
        "https://assets.coingecko.com/coins/images/12559/standard/Avalanche_Circle_RedWhite_Trans.png?1696512369",

    ]
    const chainName = ["Ethereum", "Solana", "Arbitrum", "Avalanche"]
    return (
        <div className="wrap bg-white bg-opacity-5 py-16">
            <Container>
                <div className="heading text-center text-4xl text-white mb-10">
                    <h1>Supported <span className='text-primary'>Chains</span></h1>
                </div>
                <div className="grid grid-cols-2 lg:grid-cols-4 gap-10">
                    {chainImg.map((i, index) => (
                        <div className="wrap flex flex-col items-center">
                            <img src={i} alt="chain" className='rounded-full w-[100px] mb-3' />
                            <span className='uppercase text-gray-300 text-lg'>{chainName[index]}</span>
                        </div>
                    ))}
                </div>
            </Container>
        </div>
    )
}
