import React from 'react'
import Container from './Container'
import arrow from "../svg/arrow.svg"
import chart from "../svg/chart.svg"
import wallet from "../svg/wallet.svg"
import recycle from "../svg/recyle.svg"
import Card from './UI/Card'


export default function Features() {
    return (
        <div className="wrap py-32" id='feature'>
            <Container>
                <div className="heading text-center text-gray-200 mx-auto">
                    <h1 className='text-4xl lg:text-5xl font-bold mb-3'>Beyond Traditional Crypto Tools</h1>
                    <span className='lg:text-xl'> Embark on a Journey of Discovery with Our Innovative Ethereum & SOL Pair Finder Bot, <br /> Revolutionizing the Way You Explore New Trading Opportunities on the Blockchain.</span>
                </div>

                <div className="grid lg:grid-cols-3 py-10 gap-10">
                    <div className="card-wrap flex flex-col gap-10 justify-between">
                        <Card icon={arrow} title={"FIND NEW PAIRS"} text={'Discover the latest Ethereum & SOL pairs with ease, unlocking endless trading opportunities.'} />
                        <Card icon={wallet} title={"100% SECURE "} text={'Rest assured knowing your assets are safeguarded by top-notch security protocols.'} />
                    </div>
                    <div className="img-wrap bg-primary p-8 rounded-3xl max-h-[100%] overflow-clip">
                        <div className="heading leading-7 text-gray-200">
                            <h1 className='font-extrabold text-2xl mb-2'>IOS & ANDROID ACCESS</h1>
                            <span className='text-md'>Seamlessly access our innovative bot on both iOS and Android devices for ultimate convenience.</span>
                        </div>

                        <img src="/asset/bot.png" alt="bot" className='w-full' />
                    </div>
                    <div className="card-wrap flex flex-col gap-10 justify-between">
                        <Card icon={chart} title={"PAIR ANALYSIS"} text={'Dive deep into comprehensive pair analysis tools, empowering informed decision-making.'} />
                        <Card icon={recycle} title={"REAL TIME DATA"} text={'Stay ahead of the curve with up-to-the-minute data feeds, ensuring you never miss a beat in the market.'} />
                    </div>
                </div>

                <div className="btn-wrap text-center mt-10">
                    <a target='_blank' href='https://t.me/alphaiportal' className='font-bold text-gray-200 rounded-full p bg-primary p-4 px-10 text-md'>GET STARTED</a>
                </div>
            </Container>
        </div>
    )
}
