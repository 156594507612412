import React from 'react'
import Container from './Container'
import { FaSearch, FaShieldAlt } from 'react-icons/fa'

export default function OurBots() {
    return (
        <div className="wrap pt-10 pb-32">
            <Container>
                <div className="heading text-center text-gray-200">
                    <h1 className='text-5xl font-extrabold mb-2'>Alpha <span className='text-primary'>AI Bots</span></h1>
                    <span className='text-lg text-gray-300'> Find the perfect plan to suit your trading needs.</span>
                </div>

                <div className="flex flex-wrap justify-center gap-10 mt-10">
                    <div className="boxes text-center w-[400px] bg-primary bg-opacity-2 text-white p-10 rounded-3xl">
                        <div className="img-wrap flex justify-center mb-8">
                            <span className='bg-secondary rounded-full p-5'><FaShieldAlt size={50} /> </span>
                        </div>
                        <div className="text-wrap text-secondary">
                            <h1 className='font-extrabold text-3xl'>Alpha AI Audit</h1>
                            <span className='text-md font-thin'>Our automated AI contract audit provides efficient and accurate analysis.</span>
                        </div>

                        <div className="btn-wrap mt-10">
                            <a target='_blank' href='https://docs.alphai.bot/alpha-audit' className='font-bold text-secondary rounded-full p bg-white p-4 px-10 text-md'>Learn More</a>
                        </div>
                    </div>

                    <div className="boxes text-center w-[400px] bg-primary bg-opacity-2 text-white p-10 rounded-3xl">
                        <div className="img-wrap flex justify-center mb-8">
                            <span className='bg-secondary rounded-full p-5'><FaSearch size={50} /> </span>
                        </div>
                        <div className="text-wrap text-secondary">
                            <h1 className='font-extrabold text-3xl'>New Pair Finder</h1>
                            <span className='text-md font-thin'>The new pair Finder Bot simplifies trading by swiftly identifying new pairs onchain.</span>
                        </div>

                        <div className="btn-wrap mt-10">
                            <a target='_blank' href='https://docs.alphai.bot/alpha-finder/supported-chains' className='font-bold text-secondary rounded-full p bg-white p-4 px-10 text-md'>Learn More</a>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    )
}
