import React from 'react'

export default function PriceCard({ plan, price, text, benefit }) {
    return (
        <div className="card  bg-white p-10 rounded-3xl bg-opacity-10 flex flex-col justify-between">
            <div className="heading">
                <div className="flex">
                    <span className='bg-primary rounded-full p-2 text-xs font-bold text-white'>{plan}</span>
                </div>
                <h1 className='text-white font-extrabold text-4xl py-4'>{price}</h1>
                <span className='text-gray-300'>{text}</span>
            </div>

            <div className="wrap">
                <div className="text-wrap mt-10">
                    <span className='font-bold text-lg text-primary'>Benefits</span>
                </div>

                <ul className='mt-2'>
                    <ul className=' list-inside list-disc text-primary'>
                        {benefit.map((i, index) => (
                            <li key={index}><span className='text-white'>{i}</span></li>
                        ))}
                    </ul>
                </ul>

            </div>
            <div className="btn-wrap mt-10">
                <a target='_blank' href='https://app.uniswap.org/#/swap?&chain=mainnet&use=v2&outputCurrency=0x3f6bcdc39f8ac795e7168febad7e262e325450f7' className='font-bold text-gray-200 rounded-full p bg-primary p-4 px-10 text-md w-full mb-3'>BUY $ALAI</a>
                <a target='_blank' href='https://t.me/alphaiportal' className='font-bold text-primary rounded-full p bg-white p-4 px-10 text-md w-full'>GET STARTED</a>
            </div>
        </div>
    )
}
